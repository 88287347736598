<template lang="pug">
.fill-height.d-flex.flex-column.justify-center(ref="form" lazy-validation @submit.prevent.stop="onSubmit")
  .mt-auto.d-flex.flex-column.justify-center.align-center
    v-icon(size="100" color="primary") mdi-check-circle
    h3.primary--text ¡Todo listo!
    p Ahora puedes ingresar sin problemas
  .mt-auto.mb-5
    v-btn.btnRegister(
      color="primary"
      @click="finish"
      block
      min-height="40px"
      dark) Continuar
</template>
<script>

export default {
  methods: {
    async finish () {
      this.$router.push('/ot')
    }
  }
}
</script>
<style lang="scss" scoped>
.btnRegister {
  text-transform: none;
}
</style>
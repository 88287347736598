<template lang="pug">
v-container.ma-0.pa-0(style="width: auto" fluid)
    v-menu(
      
      key="mainMenu"
      rounded
      :left="true"
      offset-y
      nudge-left="20"
       
    )
      <template v-slot:activator="{ attrs, on }">
          v-btn.pa-2(
            style="min-width: fit-content"
            elevation="0"
            
            class="white--text"
            v-bind="attrs"
            v-on="on"
          ) 
            v-icon.ma-0.pa-0(color="black") mdi-car-select
      </template>
      v-card
        v-card-title.py-3.body-1.font-weight-medium(style="background-color: #1A1796; color: #FFFFFF") Cambiar vehículo
        v-list
          v-list-item(
            v-for="vehicle in customerData.vehicles"
            :key="vehicle.id"
            link
          )
            v-list-item-title(v-text="vehicle.model_display?.split(' (')[0]" @click="changeVehicle(vehicle)")
      
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

  export default {
    data: () => ({
      btns: [
        ['Removed', '0'],
        ['Large', 'lg'],
        ['Custom', 'b-xl'],
      ],
      colors: ['deep-purple accent-4', 'error', 'teal darken-1'],
      items: [...Array(4)].map((_, i) => `Item ${i}`),
    }),

    computed: {
      ...mapGetters('ot', ['customerData'])
    },
    methods: {
      ...mapActions('ot', ['getOTData']),

      changeVehicle(vehicle) {
        this.$emit('changeVehicle', vehicle)
      }  
      },
  }
</script>
<template lang="pug">
.login-layout
  v-container.login-container
    v-row.flex-md-row-reverse
      v-col.d-md-flex.flex-md-column.justify-md-center.column-form-login(cols="12" md="5")
        .form.container
          .logotipo
          div.my-md-7
            h2 Bienvenidos a
            h2 Garage Argentino
          v-form(ref="form" lazy-validation @submit.prevent.stop="onSubmit")
            p Rut
            v-text-field(
              v-model="form.rut"
              placeholder="Ej: 12345678-1"
              :rules="[v => !!v || 'El rut es requerido']"
              v-maska="maskaRut" data-maska="1.11#-V" data-maska-tokens="1:[0-9]:repeated|V:[0-9Kk]" data-maska-reversed
              solo dense outlined flat
            )

            p Patente
            v-text-field(
              v-model="form.patent" solo dense outlined flat placeholder="Ej: AABB00" :rules="[v => !!v || 'La patente es requerida']"
              v-maska:[patentFormat]
            )

            v-btn(color="#407BFF" type="submit" min-width="100%" min-height="40px" dark) Entrar
            p.mt-2(v-if="notFoundError" style="font-size: 14px;").red--text {{ notFoundError }}
            .d-flex.justify-center.align-center.mt-2
              p.mr-2 Primera vez aquí? #[strong #[router-link(to="/register") Crea una cuenta]]
            //- El rut o la patente no existen. Intenta nuevamente.
      v-col.pa-0(cols="12" md="7")
        .login-image
</template>
<script>
import { mapActions } from 'vuex'
import { vMaska } from 'maska'

export default {
  directives: { maska: vMaska },

  data () {
    return {
      notFoundError: null,
      form: {
        rut: null,
        patent: null
      },
      maskaRut: { unmasked: null }
    }
  },
  computed: {
    patentFormat () {
      return {
        mask: 'AAAAAA',
        tokens: {
          A: { pattern: /[a-zA-Z0-9]/ }
        },
        postProcess: (value) => value.toUpperCase()
      }
    },
    rawRut () {
      return this.maskaRut.unmasked
    }
  },
  methods: {
    ...mapActions('ot', ['getOTData']),
    async onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }
      const params = { rut: this.rawRut, patente: this.form.patent }
      await this.getOTData({ params }).then(
        response => {
          if (response.status >= 200 && response.status < 300) {
            localStorage.setItem('rut', JSON.stringify(this.rawRut))
            localStorage.setItem('patente', JSON.stringify(this.form.patent))
            this.notFoundError = null
            this.$router.push({ name: 'OT' })
          } else {
            this.notFoundError = response.data?.Error
          }
        }
      )
    }
  },
  created () {
    localStorage.clear()
  }
}
</script>
<style lang="scss" scoped>
.login-layout{
    background: #FFFFFF;
    height: 100%;
    display:flex;
    justify-content: center;
    flex-direction: column;
    gap:18px;
    .login-container {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      h2 {
        text-align: center;
        font-weight: 600;
      }
      p {
        color: #407BFF;
        margin-bottom: 3px;
      }
      .logotipo {
        background-image: url('./../assets/Logotipo.png');
        height: 34px;
        width: 100px;
        background-size: cover;
        background-position: center;
        margin: auto;
        margin-bottom: 20px;
      }
      @media (max-width: 2560px) and (min-width: 769px) {
        padding: 0px;
      }
    }
    .login-image {
      background-image: url('./../assets/login-illustration-sm.png');
      height: 300px;
      background-size: cover;
      background-position: center;
      margin-top: 20px;
      @media (max-width: 2560px) and (min-width: 769px) {
        height: 100vh;
        background-image: url('./../assets/login-illustracion-md.png');
        margin-top: 0px;
      }
    }
  }
  @media (max-width: 2560px) and (min-width: 769px) {
        .column-form-login {
          padding: 0 4rem 0 3rem;
          .form.container {
            max-width: 80%;
          }
        }
      }

</style>

<template lang="pug">
v-card(elevation="0")
  .d-flex.justify-space-between.pa-4.mb-5
  
    v-btn.back-button-mobile(elevation="0" color="black" @click="goToLogin" icon)
      v-icon mdi-arrow-left
    


    v-btn.back-button-desktop(elevation="0" @click="goToLogin")
      v-icon mdi-arrow-left
      span.text-capitalize Volver

    //- div.logotipo

    div(class="d-flex justify-content-between")
      MainMenu(@changeVehicle="changeVehicle")
      div(class="d-lg-none d-block")
        v-btn.download-button(elevation="0" @click="downloadOT(OT[0])")
          div.ml-1
            DownloadIcon
  div(class="customer-data")
    v-col.d-flex.flex-column.align-items-center.text-center.justify-center
      h4 Hola {{ customerData.name }} {{ customerData.lastname }}
      p(v-if="OT.length > 0") OT N° {{ OT[0].id }}
      v-row(class="d-none d-lg-flex justify-center mt-2")
        v-col(cols="12")
          v-row(align="center" justify="space-around")
            v-btn(outlined=true tile=true color="primary" :loading="$store.state.ot.isDownloading" @click="downloadOT(OT[0])").download-button.text-capitalize
              span Descargar
              div.ml-1
                DownloadIcon
      v-row
        v-col(cols="3").d-flex.flex-column.align-content-center.justify-center
          v-btn.carousel-button(fab dark color="#FFB563" elevation="0" @click="paginate('prev')" v-show="count > 0 && page > 1")
            v-icon(color="#934500" large) mdi-chevron-left
        v-col(cols="6")
          .auto-image
        v-col(cols="3").d-flex.flex-column.align-content-center.justify-center
          v-btn.carousel-button(fab dark color="#FFB563" elevation="0" @click="paginate('next')" v-show="page < count")
            v-icon(color="#934500" large) mdi-chevron-right
      div(v-if="OT.length > 0")
        v-chip.mt-3(label :text-color="getStatusTextColor(OT[0].state)" :color="getStatusColor(OT[0].state)") {{ OT[0].state }}
      p.mt-3 {{ vehicleData.brand_name }} {{ vehicleData.model }}
      h2 {{ vehicleData.patent }}
      div
        v-btn.pay-button.mt-3.text-capitalize(
          v-if="isDeposit"
          @click="payment"
          color="primary" dark
        ) Pagar Abono
        v-btn.pay-button.mt-3.text-capitalize(
          v-else-if="isFinalPayment"
          @click="payment"
          color="primary" dark
        ) Pagar
      //- p.primary--text.mt-2 Saldo pendiente: {{ customerData.customer_pending_debt | currency }}
</template>
<script>
import { mapActions } from 'vuex';
import otStatusColors from '../../shared/otStatusColors.json'
import DownloadIcon from '../shared/icons/DownloadIcon.vue'
import MainMenu from './MainMenu.vue'
export default {
  props: {
    OT: {
      type: Array,
    },
    count: {
      type: Number,
    },
    page: {
      type: Number,
    },
    customerData: {
      type: Object,
    },
    vehicleData: {
      type: Object,
    },
  },
  components: {
    MainMenu,
    DownloadIcon
  },

  data() {
    return {}
  },
  computed: {
    isDeposit() {
      return (
        // console.log(this.OT.length > 0 && this.OT[0]),
        this.OT.length > 0 && this.OT[0].is_deposit_needed &&
        this.OT.length > 0 && this.OT[0].total_pending_deposit > 0 &&
        this.OT.length > 0 && this.OT[0].total_pending_amount > 0 &&
        !['Ingresado', 'En diagnóstico', 'Diagnosticado'].includes(this.OT.length > 0 && this.OT[0].state)
      )
    },
    isFinalPayment() {
      return (
        !this.isDeposit &&
        this.OT.length > 0 && this.OT[0].is_final_payment_enabled &&
        this.OT.length > 0 && this.OT[0].total_pending_amount > 0
      )
    },
  },
  methods: {
    ...mapActions('ot', ['downloadOTPdf']),
    getStatusTextColor(state) {
      return otStatusColors[state]
        ? otStatusColors[state].textColor
        : otStatusColors.default.textColor
    },
    getStatusColor(state) {
      return otStatusColors[state]
        ? otStatusColors[state].color
        : otStatusColors.default.color
    },
    payment() {
      window.location.href = this.isDeposit
        ? this.OT.length > 0 && this.OT[0].link
        : this.OT.length > 0 && this.OT[0].final_link
    },
    goToLogin() {
      this.$router.push({ name: 'login' })
    },
    paginate(index) {
      // console.log('paginando')
      this.$emit('getNext', index)
    },

    async downloadOT(ot) {
      const type = 'recepcion'
      await this.downloadOTPdf({
        id: ot.id,
        type
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: {
            type: response.headers['content-type'],
          },
        })
        const blobUrl = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobUrl
        const nombreArchivo =
          type === 'recepcion'
            ? `Recepción OT ${ot.id}.pdf`
            : `Entrega OT ${ot.id}.pdf`
        tempLink.setAttribute('download', nombreArchivo)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      })
    },
    changeVehicle(vehicle) {
      this.$emit('changeVehicle', vehicle)
    }
  },
}
</script>
<style lang="scss" scoped>
.v-card {
  p {
    margin-bottom: 0px;
    font-size: small;
  }
  h4 {
    font-weight: 600;
  }
  .back-button-mobile {
    display: block;
  }
  .back-button-desktop {
    display: none;
  }
  .download-button {
   border-radius: 6px;
  }
  .pay-button {
    width: 100%;
  }

  @media (max-width: 2560px) and (min-width: 769px) {
    h4 {
      font-size: x-large;
    }
    p {
      font-size: large;
    }
    .pay-button {
      display: inline;
      margin-bottom: 10px;
      width: auto;
    }
    .back-button-mobile {
      display: none;
    }
    .back-button-desktop {
      display: block;
    }
    .logotipo {
      background-image: url('./../../assets/Logotipo.png');
      height: 50px;
      width: 120px;
      background-size: contain;
      background-position: center;
    }
    .customer-data {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .carousel-button {
        display: none;
      }
    }
    .auto-image {
      background-image: url('./../../assets/car-illustration.png');
      background-size: contain;
      background-position: center;
      height: 100px;
      margin-top: 30px;
    }
  }
}
.auto-image {
  background-image: url('./../../assets/car-illustration.png');
  background-size: contain;
  background-position: center;
  height: 70px;
  margin-top: 30px;
}
</style>

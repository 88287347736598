<template lang="pug">
v-container.main-container(fluid)
  v-row.flex-md-row.flex-grow-1.ma-0
    v-col(cols="12" md="4").pa-0
      CustomerData(:OT="ot" :count="count" :page="page" @getNext="getNextPage" @changeVehicle="changeVehicle" :customerData="customerData" :vehicleData="vehicleData")
    v-col(cols="12" md="8").pa-0
      TabsView(@getNext="getNextPage")
  ContactFooter
</template>
<script>
import CustomerData from '@/components/OTDetail/CustomerData.vue'
import BudgetList from '@/components/OTDetail/BudgetList.vue'
import ContactFooter from '@/components/OTDetail/ContactFooter.vue'
import TabsView from './TabsView.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    CustomerData,
    BudgetList,
    ContactFooter,
    TabsView,
  },
  data () {
    return {
      ot: [],
      page: 1,
      count: 0,
      queryParams: null,
      customerData: {},
      vehicleData: {},
    }
  },
  methods: {
    ...mapActions('ot', ['getOTData']),
    async fetchData () {
      let rut;
      let patent;
      if(this.queryParams?.login) {
        rut = this.queryParams.rut
        patent = this.queryParams.patent
        localStorage.setItem('rut', JSON.stringify(rut))
        localStorage.setItem('patente', JSON.stringify(patent))
      } else {
        rut = JSON.parse(localStorage.getItem('rut'))
        patent = JSON.parse(localStorage.getItem('patente'))
        if (!rut && !patent) {
          rut = JSON.parse(localStorage.getItem('customer')).rut
          patent = JSON.parse(localStorage.getItem('vehicle')).patent
        }
      }
      
      await this.getOTData({ params: { page: this.page, rut: rut, patente: patent?.toUpperCase() } }).then(
        response => {
          if (response.status >= 200 && response.status < 300) {
            this.ot = response.data.orders
            this.count = response.data.count
            this.customerData = response.data.customer
            this.vehicleData = response.data.vehicles[0]
          }
        }
      )
    },
    getNextPage (data) {
      if (data === 'prev') {
        this.page = this.page > 1 ? this.page - 1 : 1
      } else {
        this.page = this.page + 1
      }
      this.fetchData()
    },


    async changeVehicle (vehicle) {
        // console.log('este es el vehiculo', vehicle)
        const rut = JSON.parse(localStorage.getItem('rut'))
      // patent = JSON.parse(localStorage.getItem('patente'))
        const patent = vehicle.patent
        
        await this.getOTData({ params: { page: this.page, rut: rut, patente: patent?.toUpperCase() } })
          .then(
            response => {
              if (response.status >= 200 && response.status < 300) {
                this.ot = response.data.results
                this.count = response.data.count
                localStorage.setItem('patente', JSON.stringify(patent))
              }
          });
      }
  },

  mounted() { 
    this.queryParams = this.$route.query
    if(!this.$route.query.login) {
      this.fetchData()
    }
  },  
  watch: {
    queryParams () {
      if(this.queryParams.login) {
        this.fetchData()
      }
    }
  },
  
}
</script>
<style lang="scss" scoped>
.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
}
</style>

import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const AuthStore = {
  namespaced: true,
  state: {
    user: {
      name:"",
      last_name:"",
      address:"",
      email:"",
      contact:"",
      rut: null
    }
  },
  getters,
  mutations,
  actions
}

export default AuthStore

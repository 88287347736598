import axios from '../../plugins/axios'

export const setUser = (_, data) => {
  localStorage.setItem('customer', JSON.stringify(data))
};

export const setVehicle = (_, data) => {
  localStorage.setItem('vehicle', JSON.stringify(data))
};


export const registerUser = async ({ commit }, data) => {
  return axios.post('/inventory/register_new_client/', data)
    .then(res => {
      const data = res.data.results;
      commit('SET_USER', data)
      return res
    })
    .catch(err => err.response)
};

export const validateEmail = async (_, data) => {
  return axios.post('/inventory/validate_verification_email_code/', data)
    .then(res => res)
    .catch(err => err.response)
}
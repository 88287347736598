<template lang="pug">
SideModal(v-model="isOpen" max-width="600")
  h3.title-style.text-center.mb-4 Agendar servicios
  v-form(ref="form" @submit.prevent="handlerAppointmentSave")
    v-container
      v-row(cols="12")
        v-col
          label.primary--text Vehículo
            v-autocomplete(
              ref="vehicle"
              v-model="form.vehicle"
              outlined
              dense
              :item-text="item => item.model_display?.split(' (')[0] + ' - ' + item.patent"
              return-object
              :items="customerData.vehicles"
              color="primary"
              :rules="rules.vehicle"
            )
      v-row(cols="12")
        v-col.py-0(cols="12")
          label.primary--text Servicio
            v-select(
              v-model="form.service"
              placeholder="Selecciona un servicio"
              style=" height: fit-content; margin: 0; padding: 0" 
              outlined
              return-object
              :item-text="({ name, duration }) => name + ' - ' + duration + ' hora(s)'"
              dense
              @change="fetchTimeBlocks()"
              :items="services"
            )

      v-row(cols="12")
        v-col.py-0
          v-menu(
            ref="showMenuDatePicker"
            v-model="showMenuDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          )
            <template v-slot:activator="{ on }">
              label.primary--text Fecha
                v-text-field(
                  outlined
                  append-icon="mdi-calendar" 
                  v-model="form.date"
                  v-on="on"
                  dense
                  :rules="rules.date"
                )
            </template>

            v-date-picker(
              v-model="form.date" 
              no-title
              :min="today" 
              :allowed-dates="allowedDates" 
              locale="cl-Es" 
              @input="showMenuDatePicker = false, fetchTimeBlocks()"
            )
        v-col.py-0(cols="12")
          label.primary--text Horario
            div.black--text
              div(v-if="!form.date") Selecciona una fecha para ver los horarios disponibles
              div(v-else-if="!form.service") Selecciona un servicio para ver los horarios disponibles
              div(v-else-if="loadingBlocks" class="d-flex align-center flex-column")
                v-progress-circular(:indeterminate="true" color="primary" height="5")
              div(v-else-if="timeBlocks.length === 0 && !loadingBlocks") No hay horarios disponibles
            v-chip-group(v-model="form.time" column :rules="rules.time" active-class="actived-hour")
              v-chip(
                v-for="(block, index) in timeBlocks"
                outlined
                large
                label
                :key="index" 
                color="primary"
                :value="block.hour"
              ) {{ block.hour }}
            div.error--text(v-if="validated && !form.time") Este campo es requerido
        v-col.py-0.mt-4.mb-4(cols="12")
          label.primary--text Comentario
            v-textarea(
              v-model="form.customer_comment"
              outlined
              rows="4" 
              no-resize
              :rules="[v => (v === null || v && v.length <= 1000) || 'Carácteres máximos permitidos: 1000']"
            )
      
      v-alert.mb-4(v-if="errorMessage" type="error") {{ errorMessage }}
      v-btn(
        color="primary" 
        block
        :loading="loadingSubmit"
        style="font-weight: 400;"
        type="submit"
      ) Guardar
  SideModal(v-model="openNewVehicleModal" max-width="600")
    v-container
      h2.mb-5 Nuevo vehículo
      
    
</template>
  
<script>
import SideModal from '@/components/shared/SideModal.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    SideModal,
  },

  data () {
    return {
      isOpen: false,
      form: {
        vehicle: null,
        service: null,
        date: null,
        time: null,
        customer_comment: '',
      },
      rules: {
        vehicle: [v => !!v || 'Este campo es requerido'],
        service: [v => !!v || 'Este campo es requerido'],
        date: [v => !!v || 'Este campo es requerido'],
      },
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 10),      
      dateFormatted: null,        
      showMenuDatePicker: false,     
      focus: this.focusDay,

      vehicle: null,
      openCustomerForm: false,
      openNewVehicleModal: false,

      currentCustomer: null,
      currentTime: this.getCurrentTime(),
      customerComments: null,
  
      saveSuccess: false,
      saveError: false,
      formError: false,
      errorMessage: null,

      workingHours: {
        start: '08:00',
        end: '18:00',
      },

      tryingSave: false,
      rescheduling: false,

      timeBlocks: [],
      loadingBlocks: false,
      loadingSubmit: false,
      validated: false,
    };
  },
  computed: {
    ...mapGetters('schedule', ['focusDay', 'services', 'currentAppointment']), 
    ...mapGetters('ot', ['OT', 'customerData']),
    onEdit () {
      return !!this.currentAppointment?.id
    },
  },
  created () {
    this.listServices();
    this.formatDate2();
  },

  methods: {
    ...mapActions('schedule', [
      'listServices',
      'createAppointment',
      'updateCurrentAppointment',
      'rescheduleAppointment',
      'getTimeBlocksByService'
    ]),
    ...mapActions('ot', ['getOTData']),

    async open () {
      if(this.currentAppointment?.id) {
        this.rescheduling = true;
        this.form.vehicle = this.currentAppointment.vehicle;
        this.form.service = this.currentAppointment.service;
        this.form.date = this.currentAppointment.start_at.slice(0, 10);
        this.form.customer_comment = this.currentAppointment.customer_comment;

        await this.fetchTimeBlocks();
        this.form.time = this.currentAppointment.start_at.slice(11, 16);
        this.addBlock({ hour: this.form.time })

      }
      else {
        this.handleResetAppointment()
        this.getCurrentVehicle();
        this.getCustomer();
      
      }
      this.isOpen = true
    },

    allowedDates: 
      val => new Date(val).getDay() !== 6
    ,
    
    formatDate (date) {
      if (!date) return null
      const [year, month, day,] = date?.split('-')
      const formDate = `${month}/${day}/${year}/`
      const today = new Date(formDate);
      
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return today.toLocaleDateString('cl-Es', options).slice(0, 1).toUpperCase() + today.toLocaleDateString('cl-Es', options).slice(1)
    },

    formatDate2 () {      
      const today = new Date();
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      const result = today.toLocaleDateString('cl-Es', options).slice(0, 1).toUpperCase() + today.toLocaleDateString('cl-Es', options).slice(1)
      this.dateFormatted = result;
      return result;
    },
    async handlerAppointmentSave () {
      this.validated = true
      this.errorMessage = null

      if (!this.$refs.form.validate()) return
      if (!this.form.time) return

      this.loadingSubmit = true
      if (this.onEdit) {
        const body = {
          url_reschedule: this.currentAppointment.url_reschedule.replace('api/', ''),
          newDate: {
            start_at: `${this.form.date}T${this.form.time}`,
          }
        }

        const { data, error } = await this.rescheduleAppointment(body)
        if (!error) {
          this.refreshCustomerData(data.customer.rut, data.vehicle.patent)
          this.isOpen = false
        } else {
          if (error.response.status === 400) {
            this.errorMessage = error.response.data?.start_at[0] || error.response.data?.non_field_errors[0] || 'No se pudo agendar el servicio'
          }
        }
      } 
      else {
        const newEvent = {
          start_at: `${this.form.date}T${this.form.time}`,
          customer: this.currentCustomer.id,
          requested_by_customer: true,
          service: this.form.service.id,
          customer_comment: this.form.customer_comment,
          vehicle: this.form.vehicle.id
        }
        
        const { data, error } = await this.createAppointment(newEvent)
        console.log(data, 'data')
        if (!error) {
          this.refreshCustomerData(data.customer.rut, data.vehicle.patent)
          this.isOpen = false
        } else {
          this.errorMessage = error.response.data?.start_at[0] || error.response.data?.non_field_errors[0] || 'No se pudo agendar el servicio'
        }
      }
      this.loadingSubmit = false
    },

    handleResetAppointment() {
      // Obtiene la fecha actual, si es domingo, suma un día
      const today = new Date();
      const selectedDay = today.getDay()
      const selectedDate = today.toISOString().slice(0, 10);
      if(selectedDay === 0) {
        const nextDay = Number(today.getTime()) + 86400000
        const nextDayFormatted = new Date(nextDay).toISOString().slice(0, 10)
        this.form.date = nextDayFormatted
      } else {
        this.form.date = selectedDate
      }
      this.form.time = null
      this.form.service = null
      this.form.vehicle = null
      this.form.customer_comment = ''
      this.validated = false
      this.errorMessage = null
      this.updateCurrentAppointment(null)
      this.timeBlocks = []
      this.$refs.form?.resetValidation()
    },

    getCurrentTime() {
      const today = new Date();
      return `${today.getHours().toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}`
    },

    getCurrentVehicle () {
      const vehicle = this.customerData.vehicles.filter(vehicle => vehicle.patent === this.customerData.vehicles[0].patent)
      this.form.vehicle = vehicle[0]
    },
    
    getCustomer () {
      this.currentCustomer = this.customerData.customer
    },

    async refreshCustomerData (rut, patent) {
      const params = { rut, patente: patent}
      await this.getOTData({ params })
    },

    addBlock (block) {
      const found = this.timeBlocks.find(b => b.hour === block.hour)
      if (!found) {
        // Agrega el bloque seleccionado en la lista de bloques, en el orden correcto
        const timeToInt = time => Number(time.split(':').join(''))
        const index = this.timeBlocks.findIndex(b => timeToInt(b.hour) > timeToInt(block.hour))
        if (index === -1) {
          this.timeBlocks.push(block)
        } else {
          this.timeBlocks.splice(index, 0, block)
        }
        this.timeBlocks = [...this.timeBlocks]
      }
    },

    async fetchTimeBlocks () {
      this.form.time = null
      if (!this.form.service || !this.form.date) return
      this.loadingBlocks = true;
      const { service, date } = this.form
      const params = { serviceId: service.id, date }
      const { data } = await this.getTimeBlocksByService(params);
      this.timeBlocks = data;
      this.loadingBlocks = false;
    },
    
  },

  watch: {
    focusDay (val) {
      this.dateFormatted = this.formatDate(val)

    },
    isOpen (val) {     
      if(val === false ) {
        this.handleResetAppointment();
      }    
    },
  },
}
</script>
<style lang="scss" scoped>

.time-date {
  color: #3c4043E0;
  font-size: 0.85rem;
  font-weight: 500;
  
  
  h3 {
    font-weight: 500;
    font-size: 0.85rem;
  }

}
.time-date:hover {
  background: #70757a14;
  border-radius: 0.4rem;
  cursor: pointer;
}

.time-date h3:hover {
  text-decoration: underline;
  background: none;
}

input:invalid + span::after {
  position: absolute;
  color: red;
  content: "✖";
  padding-left: 5px;
}

input:valid + span::after {
  position: absolute;
  color: green;
  content: "✓";
  padding-left: 5px;
}

input[type = 'time']:focus::-webkit-calendar-picker-indicator {
    display: block;
    padding: 0 0.2;
    margin: 0;
    cursor: pointer;

}

input::-webkit-calendar-picker-indicator {
    padding: 0 0.2;
    margin: 0;
    display: none;
}

.hint {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #3c404394;
  margin-left: 1.7rem;

  p {
    padding-top: 0.5rem;
  }

}
.title-style {
  color: #4C7AEC;
}
.subtitle {
  padding-top: 20px;
  padding-left: 20px;
}
.otNumber {
  width: 80%;
}

.actived-hour {
  background: #4C7AEC;
  font-weight: 500;
  border: 2px solid #4C7AEC;
  cursor: pointer;
}

</style>

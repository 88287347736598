export const STATES = {
  SCHEDULED: {
    name: 'Agendado',
    color: '#7B90DA',
    value: 'SCHEDULED',
  },
  RESCHEDULED: {
    name: 'Reagendado',
    color: '#AEBDF0',
    value: 'RESCHEDULED',
  },
  REQUESTED: {
    name: 'Solicitado',
    color: '#AEBDF0',
    value: 'REQUESTED',
  },
  DONE: {
    name: 'Realizado',
    color: '#1BC47D',
    value: 'DONE',
  },
  CANCELED: {
    name: 'Cancelado',
    color: '#FF7E35',
    value: 'CANCELED',
  },
}

const mutations = {
  'SET_OT' (state, value) {
    state.OT = value
  },

  'SET_CUSTOMERDATA' (state, value) {
    state.customerData = value
  }
}

export default mutations

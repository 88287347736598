<template lang="pug">
v-container.fill-height-container(fluid)
  .showOnDesktop.login-layout
    .login-container
      v-row
        v-col.pa-0(cols="12" sm="6" md="7")
          .login-image
        v-col.d-flex.flex-column.align-center.justify-center(cols="12" sm="6" md="5")
          .form-container.px-8
            h2.mb-6 Bienvenidos a Garage Argentino
            RegisterFormStep1(v-if="step === 1" @next="step++")
            RegisterFormStep2(v-if="step === 2" @next="step++" @prev="step--")
            RegisterFormStep3(v-if="step === 3" @next="step++" @prev="step--")
  .showOnMobile.fill-height.px-4
    RegisterFormStep1(v-if="step === 1" @next="step++")
    RegisterFormStep2(v-if="step === 2" @next="step++" @prev="step--")
    RegisterFormStep3(v-if="step === 3" @next="step++" @prev="step--")
</template>
<script>
import RegisterFormStep1 from '@/components/Register/RegisterFormStep1'
import RegisterFormStep2 from '@/components/Register/RegisterFormStep2'
import RegisterFormStep3 from '@/components/Register/RegisterFormStep3'
export default {
  components: {
    RegisterFormStep1,
    RegisterFormStep2,
    RegisterFormStep3,
  },
  data () {
    return {
      step: 1
    }
  }
}
</script>
<style lang="scss">
.fill-height-container {
  height: 100%;
}

.showOnMobile {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}
.showOnDesktop {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}

.login-layout{
    background: #FFFFFF;
    height: 100%;
    .login-container {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      h2 {
        text-align: center;
        font-weight: 600;
      }
      p {
        color: #407BFF;
        margin-bottom: 3px;
      }
      .logotipo {
        background-image: url('./../assets/Logotipo.png');
        height: 34px;
        width: 100px;
        background-size: cover;
        background-position: center;
        margin: auto;
        margin-bottom: 20px;
      }
      @media (max-width: 2560px) and (min-width: 769px) {
        padding: 0px;
      }
    }
    .login-image {
      background-image: url('./../assets/login-illustracion-md.png');
      background-size: cover;
      background-position: center;
      height: 100vh;
      margin-top: 0px;
    }
    .form-container {
      @media (min-width: 601px) and (max-width: 768px) {
        max-width: 80%;
      }
      max-width: 90%;
    }
  }
  
  </style>
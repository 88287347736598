<template lang="pug">
v-form.fill-height.d-flex.flex-column.justify-space-between(ref="form" lazy-validation @submit.prevent.stop="onSubmit")
  .mb-2
    v-btn(icon color="primary" @click="previousStep()")
      v-icon(size="26") mdi-chevron-left
    h3.primary--text.text-left Empecemos, cuéntanos un poco de ti
    div
      v-row.pb-0.mb-0
        v-col.pb-0.mb-0(cols="12" md="6")
          p.primary--text.mb-0
            | Nombre
          v-text-field(
            v-model="form.name"
            required
            color="primary"
            outlined
            hide-details="auto"
            :rules="[rules.required]"
            dense
            )
        v-col.pb-0.mb-0(cols="12" md="6")
          p.primary--text.mb-0
            | Apellido
          v-text-field(
            v-model="form.last_name"
            required
            color="primary"
            outlined
            hide-details="auto"
            :rules="[rules.required]"
            dense
            )

        v-col.pb-0.mb-0(cols="6" md="6")
          p.primary--text.mb-0
            | Rut
          v-text-field(
            v-model="form.rut"
            required
            color="primary"
            outlined
            :rules="[rules.required]"
            hide-details="auto"
            v-maska="maskaRut"
            data-maska="1.11#-V"
            data-maska-tokens="1:[0-9]:repeated|V:[0-9Kk]"
            data-maska-reversed
            dense
            )
        v-col.pb-0.mb-0(cols="6" md="6")
          p.primary--text.mb-0
            | Teléfono
          v-text-field(
            v-model="form.contact"
            required
            color="primary"
            outlined
            :rules="[rules.required]"
            hide-details="auto"
            dense
            )

        v-col.pb-0.mb-0(cols="12" md="12")
          p.primary--text.mb-0
            | Dirección
          v-text-field(
            v-model="form.address"
            required
            color="primary"
            outlined
            hide-details="auto"
            dense
            )

        v-col.pb-0.mb-0(cols="12" md="12")
          p.primary--text.mb-0
            | Email
          v-text-field(
            v-model="form.email"
            required
            color="primary"
            outlined
            :rules="[rules.required]"
            hide-details="auto"
            dense
            )

        v-col.pb-0.mb-0(cols="12" md="12")
          p.primary--text.mb-0
            | Patente
          v-text-field(
            v-model="form.patent"
            required
            color="primary"
            outlined
            :rules="[rules.required]"
            hide-details="auto"
            dense
            v-maska:[patentFormat]
            )
  v-row.mb-0.button-row
    v-col.pb-0.mb-0(cols="12")
      p.error--text(v-if="errorText !== ''") {{ errorText }}
      v-btn.btnRegister(
        color="primary"
        type="submit"
        block
        min-height="40px"
        :disabled="!isValid"
        :loading="loading"
      ) Continuar
</template>
<script>
import { mapActions } from 'vuex'
import { vMaska } from 'maska'

export default {
  directives: { maska: vMaska },
  data () {
    return {
      loading: false,
      errorText: '',
      form: {
        name: '',
        last_name: '',
        rut: '',
        email: '',
        contact: '',
        address: '',
        patent: ''
      },
      maskaRut: { unmasked: null },
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  computed: {
    patentFormat () {
      return {
        mask: 'AAAAAA',
        tokens: {
          A: { pattern: /[a-zA-Z0-9]/ }
        },
        postProcess: (value) => value.toUpperCase()
      }
    },
    rawRut () {
      return this.maskaRut.unmasked
    },
    state () {
      return {
        rut: this.form.rut  !== '',
        email: this.form.email !== '',
        contact: this.form.contact !== '',
        patent: this.form.patent !== ''
      }
    },
    isValid () {
      return Object.values(this.state).every((value) => value === true)
    }
  },
  methods: {
    ...mapActions('auth', ['registerUser', 'setUser', 'setVehicle']),
    async onSubmit () {
      if (!this.isValid) {
        return
      }
      this.errorText = ''
      this.loading = true
      this.form.rut = this.rawRut
      const response = await this.registerUser(this.form)

      this.loading = false
      if (response.status === 200) {
        this.setUser(response.data.customer)
        this.setVehicle(response.data.vehicle)
        this.$emit('next')
      } else {
        const errors = Object.keys(response.data)
        if (errors.length > 0) {
          errors.map((e) => {
            return this.$toast.error(`${response.data[e]}` )
          })
        }
        const defaultErrorMessage = 'Ha ocurrido un error al intentar guardar los datos, por favor intenta nuevamente.'
        this.errorText = ''
        response.data.forEach((data) => {
          if (Object.hasOwn(data, 'patent')) {
            this.errorText += data.patent + ' '
          }
        })
        if (this.errorText === '') {
          this.errorText = defaultErrorMessage
        }
      }
    },
    previousStep () {
      this.$router.push('/')
    }
  },
}
</script>
<style lang="scss" scoped>
.fill-height-container {
  height: 100vh;
}
.btnRegister {
  text-transform: none;
}
.button-row {
  @media (max-width: 600px) {
    align-content: end;
  }
}
</style>
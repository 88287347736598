<template lang="pug">
.background-content
  v-container(class="pa-0")
    v-col(cols="12" md="6")
      v-card.pa-4(v-if="customerData?.appointments?.length === 0" class="text-center" color="#407BFF" dark flat)
        img(src='@/assets/schedule/emptyState.svg' alt='schedule-icon')
        p.mb-0 No hay servicios agendados aún. 
        p Empieza ahora.
      v-btn.text-capitalize(color="#FFFF" block outlined 
        @click="() => openModalAppointment(true)") Agendar nuevo servicio
    AppointmentList(:openModalAppointment="() => openModalAppointment(true)")
    ModalAppointment(ref="ModalAppointment")

</template>

<script>
import AppointmentList from '@/components/schedule/AppointmentList.vue';
import ModalAppointment from '@/components/schedule/ModalAppointment.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    AppointmentList,
    ModalAppointment,
  },
  computed: {
    ...mapGetters('ot', ['customerData'])

  },
  methods: {
    openModalAppointment() {
      this.$refs.ModalAppointment.open()
    },
  }
}
</script>

<style>
.background-content {
  background-color: #407BFF;
  padding-top: 0.5rem;
}

.v-card {
  border-radius: 32px;
}
.text-capitalize {
  text-transform: capitalize;
}
</style>
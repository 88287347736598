import { render, staticRenderFns } from "./TabsView.vue?vue&type=template&id=4b4cb9e8&scoped=true&lang=pug"
import script from "./TabsView.vue?vue&type=script&lang=js"
export * from "./TabsView.vue?vue&type=script&lang=js"
import style0 from "./TabsView.vue?vue&type=style&index=0&id=4b4cb9e8&prod&lang=scss&scoped=true"
import style1 from "./TabsView.vue?vue&type=style&index=1&id=4b4cb9e8&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b4cb9e8",
  null
  
)

export default component.exports
<template lang="pug">
v-col(cols="12" md="6" )
  v-card.mb-4.appointment-card(rounded="lg" 
    v-for="appointment in scheduledAppointments" 
    :key="appointment.id"
  )
    v-row.ma-0
      v-col.pb-0.mb-0.text-left(cols="8")
        p.pb-0.mb-0.ml-1(class="font-weight-regular text-caption") Nombre del servicio:
      v-col.pb-0.text-right(cols="4")
        v-chip.flex-grow-0.flex-shrink-1.mr-1(label text-color="white" :color="states[appointment.state].color") {{ states[appointment.state].name }}
      v-row.ma-0.py-0.ml-4
        p.pt-0.pb-1.mt--11(class="text-body-1 font-weight-bold") {{ appointment.service.name }}
      
    v-col.pt-0(cols="12")
      v-row.mb-0.ml-0(cols="12")
        v-icon.pa-0(color="#407BFF") mdi-calendar-month
        p.pl-3.mb-0(class="text-caption") {{ getDateFormatted(appointment.start_at) }}
      v-row.mb-0.ml-0.pt-0.mt-2
        v-icon.pa-0(color="#407BFF") mdi-timer-outline
        p.pl-3.mb-0(class="font-weight-regular text-caption") Tiempo aproximado: {{ appointment.service.duration }} hora(s)

      v-row.mb-0.ml-0.mt-1
        v-icon.pa-0(color="#407BFF") mdi-car-hatchback
        p.pl-3.mb-0(class="font-weight-regular text-caption") {{ appointment.vehicle.model_display.split(' (')[0] + ' - ' + appointment.vehicle.patent }}

      v-card.pa-2(v-if="appointment.state === states.RESCHEDULED.value" color="#C8A9F933" elevation="0") 
        p.mb-0(class="font-weight-regular text-caption text-left") Tu servicio fue <b>reagendado</b> para el <b> {{ getDateFormatted(appointment.start_at) }} </b>
      
      v-col.mb-0.text-right
        v-btn.text-capitalize.mr-1(v-if="appointment.state === states.RESCHEDULED.value" color="primary" small :loading="isApproving" @click="() => approveAppointment(appointment)") Aceptar
        v-btn.text-capitalize.mr-1(v-if="appointment.state !== states.CANCELED.value && appointment.state !== states.DONE.value" text color="#407BFF" small @click="() => rescheduleAppointment(appointment)") Reagendar
        v-btn.text-capitalize.ml-1(v-if="appointment.state !== states.CANCELED.value && appointment.state !== states.DONE.value" text color="#407BFF" small  @click="() => handlerCancelAppointment(appointment)") Cancelar


  v-dialog(
    v-model="dialog"
    max-width="350"
    justify="center"
  )
    v-card(class="text-center d-inline-block" color="grey lighten-4") 
      v-card.py-4.px-2
        img(
          src="@/assets/schedule/trash.svg"
          alt='trash-icon'
        )
        
        p Seguro quieres <b>cancelar cita</b> de la agenda el servicio <b> {{ currentAppointment?.service?.name }} </b> 

        v-card-actions(style="justify-content: center")
          v-btn.mt-4.px-4.text-capitalize(color="primary" outlined @click="dialog = false") Volver
          v-btn.mt-4.px-4.text-capitalize(
            :loading="false"
            color="primary"
             
            @click="cancelAppointment()"
          ) Eliminar
  
  v-alert.alert-styled(color="black" dark dense :dismissible="true" :value="isCanceled") Cita cancelada con éxito
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from '../../plugins/axios';
export default {
  props: {
    openModalAppointment: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      states: {
        SCHEDULED: {
          name: 'Agendado',
          color: '#7B90DA',
          value: 'SCHEDULED',
        },
        RESCHEDULED: {
          name: 'Reagendado',
          color: '#AEBDF0',
          value: 'RESCHEDULED',
        },
        REQUESTED: {
          name: 'Solicitado',
          color: '#AEBDF0',
          value: 'REQUESTED',
        },
        DONE: {
          name: 'Realizado',
          color: '#1BC47D',
          value: 'DONE',
        },
        CANCELED: {
          name: 'Cancelado',
          color: '#FF7E35',
          value: 'CANCELED',
        },
        
      },

      dialog: false,
      isCanceled: false,
      isApproving: false,
    }
  },
  computed: {
    ...mapGetters('ot', ['OT', 'customerData']),
    ...mapGetters('schedule', ['currentAppointment', 'scheduledAppointments']),
  },
  methods: {
    ...mapActions('ot', ['getOTData']),
    ...mapActions('schedule', ['updateCurrentAppointment']),

    async handlerCancelAppointment (appointment) {
      await this.updateCurrentAppointment(appointment)
      this.dialog = true;
    },

    async cancelAppointment () {
      const urlToCancel = this.currentAppointment.url_cancel.replace('api/', '');
      const response = await axios.post(urlToCancel);
      this.isCanceled = true;
      console.log('Servicio Cancelado');
      this.refreshCustomerData();
      this.dialog = false;
      return response;
    },

    async rescheduleAppointment (appointment) {
      await this.updateCurrentAppointment(appointment)
      this.openModalAppointment(true);
    },

    async approveAppointment (appointment) {
      const urlToApprove = appointment.url_approve_reschedule.replace('api/', '');
      const response = await axios.post(urlToApprove);
      this.isApproving = true
      console.log('Servicio aceptado!')
      this.refreshCustomerData();
      setTimeout(() => {
        this.isApproving = false;
      }, 1000);
      return response;
    },
    
    async refreshCustomerData () {
      const params = { rut: this.customerData.customer.rut, patente: this.customerData.results[0].patente }
      await this.getOTData({ params })
    },

    getDateFormatted (date) {
      const dateFormatted = `${date.split('T')[0].split('-').reverse().join('-')}`
      const hourFormatted = `${new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
      
      return `${dateFormatted} - ${hourFormatted}`
    },
  },

}
</script>

<style lang="scss" scoped>
.appointment-card {
  p {
    color: #407BFF !important;
  }
}
</style>

<style>
.text-capitalize {
  text-transform: capitalize;
}

.alert-styled {
  top: 90vh;
  width: 90vw;
  position: fixed;
}

.text-body-1 {
  margin-top: -8px;
}
</style>
<template lang="pug">
v-form.fill-height.d-flex.flex-column.justify-space-between(ref="form" lazy-validation @submit.prevent.stop="onSubmit")
  .mb-2
    v-btn(icon color="primary" @click="$emit('prev')")
      v-icon(size="26") mdi-chevron-left
    h3.primary--text.text-left Para verificar, te acabamos de mandar un código a {{hiddenEmail}}
    v-row
      v-col(cols="12")
        v-otp-input(length="6" v-model="code" color="primary" outlined hide-details="auto" :rules="rules")
  v-row.mb-2.button-row.button-spacer
    v-col(cols="12")
      p.error--text(v-if="errorText") {{ errorText }}
      v-btn.btnRegister(
        color="primary"
        type="submit"
        block
        min-height="40px"
        :disabled="!isValid"
        :loading="loading") Continuar
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      code: '',
      errorText: '',
      loading: false,
      rules: [
        v => !!v || 'Este campo es requerido'
      ]
    }
  },
  computed: {
    isValid () {
      return this.code.length === 6
    },
    hiddenEmail () {
      let email = JSON.parse(localStorage.getItem('customer')).email
      if (Number(email.slice(-6))) {
        email = email.slice(0, -6)
      }
      
      return email.replace(/(.{2})(.*)(@.*)/, (_, a, b, c) => a + '*'.repeat(b.length) + c)
    }
  },
  methods: {
    ...mapActions('auth', ['validateEmail']),
    async onSubmit () {
      if(!this.isValid) {
        return
      }
      this.errorText = ''
      this.loading = true
      const customer = JSON.parse(localStorage.getItem('customer'))
      const res = await this.validateEmail({ code: this.code, customer_id: customer.id})
      this.loading = false
      if (res.status === 200) {
        this.$emit('next')
      } else {
        this.errorText = res.data.Error || 'Ha ocurrido un error, por favor intenta nuevamente'
      }
    },
    previousStep () {
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>
.fill-height-container {
  height: 100vh;
}
.btnRegister {
  text-transform: none;
}
.desktopView {
  @media (min-width: 601px) {
    display: flex !important;
    flex-direction: column !important;
  }
}
.button-spacer {
  @media (min-width: 601px) {
    margin-top: 150px;
  }
}
.button-row {
  @media (max-width: 600px) {
    align-content: end;
  }
}
</style>
<template lang="pug">
  LogIn
</template>
<script>
import LogIn from '../components/LogIn'
export default {
  components: {
    LogIn
  }
}
</script>

import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const OTStore = {
  namespaced: true,
  state: {
    OT: null,
    isDownloading: false,
    customerData: [],
  },
  getters,
  mutations,
  actions
}

export default OTStore

<template lang="pug">
v-app
  v-main
    router-view
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
}
</style>

import { STATES } from '@/utils/constants'

export const services = (state) => state.services
export const focusDay = (state) => state.focusDay
export const currentAppointment = (state) => state.currentAppointment
export const scheduledAppointments = (state) =>
  state.appointments.filter(
    (appointment) =>
      appointment.state === STATES.REQUESTED.value ||
      appointment.state === STATES.RESCHEDULED.value
  )

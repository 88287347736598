<template lang="pug">
.footer.w-100
  .footer-desktop
    img.logo(src="@/assets/Logotipo.png")
    span ¿Necesitas ayuda? Escríbenos
    .links
      a(:href="whatsappLink" target="_blank") Whatsapp
      span &nbsp;|&nbsp;
      a(:href="emailLink" target="_blank") Correo
    .address
      v-icon mdi-map-marker
      span {{ address }}

  .footer-mobile
    span ¿Necesitas ayuda? Escríbenos
    .buttons
      v-btn.white--text(fab color="#2DA71A" :href="whatsappLink" target="_blank")
        v-icon(large) mdi-whatsapp
      v-btn.white--text(fab color="#9FB6FF" :href="emailLink" target="_blank")
        v-icon(large) mdi-email
</template>

<script>
import settings from '@/../settings'

export default {
  computed: {
    address: () => settings.locationAddress,
    whatsappLink: () => `https://wa.me/${settings.contactWhatsapp}`,
    emailLink: () => `mailto: ${settings.contactEmail}`,
  },
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
}

.footer-desktop,
.footer-mobile {
  width: 100%;
}

.footer-desktop {
  @media (max-width: 768px) {
    display: none;
  }
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 33px;

  color: #707070;
  background: #f4f4f4;
  font-weight: 400;
  font-size: 14px;
}

.footer-mobile {
  @media (min-width: 769px) {
    display: none;
  }
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  align-items: end;

  color: #fff;
  background-color: #407bff;
  font-weight: 400;
  font-size: 18px;

  padding-top: 40px;
  padding-right: 24px;
  padding-bottom: 30px;
}

.logo {
  height: 32px;
  width: auto;
}

.buttons {
  margin-top: 10px;
  display: flex;
  gap: 17px;
}
</style>

const mutations = {
  'SET_SERVICES' (state, value) {
    state.services = value
  },
  'SET_FOCUS' (state, value) {
    state.focusDay = value
  },
  'SET_APPOINTMENT' (state, value) {
    state.currentAppointment = value
  },
  'SET_APPOINTMENTS' (state, value) {
    state.appointments = value
  }

}

export default mutations
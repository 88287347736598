const currency = (money) => {
  return money.toLocaleString('es-CL', {
    style: 'currency',
    currency: 'CLP'
  })
}

export default {
  currency
}

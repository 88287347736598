import axios from '../../plugins/axios'

export const getOTData = ({ commit }, { params } = {}) => {
  return axios.get('inventory/customer_data', { params }).then(
    response => {
      commit('SET_OT', response.data.orders)
      commit('SET_CUSTOMERDATA', response.data)
      
      commit('schedule/SET_APPOINTMENTS', response.data.appointments, { root: true })
      return response
    }
  )
    .catch(err => err.response)
}

export const downloadOTPdf = ({ state }, { id, type } = {}) => {
  state.isDownloading = true
  return axios.get(`/inventory/download_ot_pdf/${id}/${type}`, { responseType: 'blob' })
    .then(res => {
      return res
    })
    .catch(err => err.response)
    .finally(() => {
      state.isDownloading = false
    })
}

export const downloadEstimatePdf = ({ state }, { id } = {}) => {
  state.isDownloading = true
  return axios.get(`/inventory/download_pdf/${id}`, { responseType: 'blob' })
    .then(res => {
      return res
    })
    .catch(err => err.response)
    .finally(() => {
      state.isDownloading = false
    })
}